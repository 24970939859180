import React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import Layout from '../components/Layout';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = Layout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "gain-insights-about-your-css"
    }}>{`Gain Insights About Your CSS`}</h1>
    <p>{`CSS Stats is a `}<strong parentName="p">{`free and open source`}</strong>{` tool that's used by many companies and
projects. It's intended to help CSS authors visualize their stylesheets and
generate documentation for a design system.`}</p>
    <h2 {...{
      "id": "how-does-it-work"
    }}>{`How does it work?`}</h2>
    <p>{`CSS Stats analyzes a web page and finds all stylesheets (linked or embedded)
and combines them. The resulting CSS is then analyzed and returned. The CSS
Stats dashboard turns the data into digestible bits of information. You can
also use the `}<a parentName="p" {...{
        "href": "/docs/api"
      }}>{`API endpoints`}</a>{` to use the raw data in custom apps
or dashboards.`}</p>
    <h2 {...{
      "id": "learn-more"
    }}>{`Learn more`}</h2>
    <p>{`CSS is a complex topic, so we've provided an ever growing series of articles:`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/learn/specificity"
        }}>{`Specificity`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "/learn/results"
        }}>{`Using the Results`}</a></li>
    </ul>
    <h2 {...{
      "id": "projects-that-use-css-stats"
    }}>{`Projects that use CSS Stats`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://primer.style/css"
        }}>{`GitHub Primer`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://components.ai"
        }}>{`Components AI`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://cloudflare.design/color"
        }}>{`Cloudflare Design`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://tachyons.io"
        }}>{`Tachyons`}</a></li>
      <li parentName="ul">{`... `}<a parentName="li" {...{
          "href": "https://www.npmjs.com/browse/depended/cssstats"
        }}>{`and many more`}</a></li>
    </ul>
    <h2 {...{
      "id": "authors"
    }}>{`Authors`}</h2>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://mrmrs.cc"
        }}>{`mrmrs`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://jxnblk.com"
        }}>{`jxnblk`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://johno.com"
        }}>{`johno`}</a></li>
      <li parentName="ul">{`... `}<a parentName="li" {...{
          "href": "https://github.com/cssstats/cssstats/graphs/contributors"
        }}>{`and some wonderful contributors`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      